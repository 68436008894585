// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/background-triangle.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  width: 100%;
  height: 100vh;
  border-radius: 8px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
}

.home section {
  margin-top: 120px;
  margin-left: 30px;
}

.home .share-link {
  margin-bottom: 30px;
  margin-left: 20px;
  position: fixed;
  bottom: 0;
}

.home h1 {
  font-size: 48px;
  font-weight: 500;
  line-height: 62px;
  margin-left: 30px;
}
.home p {
  margin-left: 30px;
  width: 40%;
  color: #e8e8ea;
  font-size: 16px;
  line-height: 21px;
}
.home button {
  margin-left: 50px;
  margin-top: 30px;
  width: 197px;
  height: 57px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 6px;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/styles/home.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,yDAA6D;EAC7D,4BAA4B;EAC5B,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,UAAU;EACV,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".home {\n  width: 100%;\n  height: 100vh;\n  border-radius: 8px;\n  background-image: url(\"../../images/background-triangle.png\");\n  background-position: inherit;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.home section {\n  margin-top: 120px;\n  margin-left: 30px;\n}\n\n.home .share-link {\n  margin-bottom: 30px;\n  margin-left: 20px;\n  position: fixed;\n  bottom: 0;\n}\n\n.home h1 {\n  font-size: 48px;\n  font-weight: 500;\n  line-height: 62px;\n  margin-left: 30px;\n}\n.home p {\n  margin-left: 30px;\n  width: 40%;\n  color: #e8e8ea;\n  font-size: 16px;\n  line-height: 21px;\n}\n.home button {\n  margin-left: 50px;\n  margin-top: 30px;\n  width: 197px;\n  height: 57px;\n  padding: 0px 8px;\n  border: 0;\n  box-sizing: border-box;\n  border-radius: 6px;\n  color: #333333;\n  font-size: 15px;\n  font-weight: 500;\n  line-height: 20px;\n  outline: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
