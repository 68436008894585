import { useNavigate } from "react-router-dom";
import ShareLink from "../components/ShareLink";
import Header from "../components/Header";

// styles
import "./styles/home.css";

export const meta = () => {
	return [
		{ title: "FinTank" },
		{ name: "description", content: "Welcome to FinTank!" },
	];
};

export default function Home() {
	const navigate = useNavigate();
	return (
		<div className="home">
			<Header />
			<section className="home-content">
				<h1>Invest in tokenized Structured Notes</h1>
				<p>
					FinTank Network is the first structured derivative RWA (real
					world asset) protocol. We create yielding vault for you to
					get return from structured equity product to diversify your
					De-Fi portfolio.
				</p>
				<button onClick={() => navigate("/products")}>Trade Now</button>
			</section>
			<ShareLink />
		</div>
	);
}
