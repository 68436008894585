import { PureComponent } from "react";
import Coin from "./Coin";
// styles
import "./styles/vault-card.css";

export default class VaultCard extends PureComponent {
	render() {
		const { cardContent } = this.props;
		return (
			<div className="vault-card">
				<div className="card-description">
					<div className="title">{cardContent.title}</div>
					<h2>{cardContent.header}</h2>
					<p className="description">{cardContent.description}</p>

					<div className="card-content">
						<span>Underlying Assets</span>
						<span>{cardContent.underlyingAssets}</span>
					</div>
					<div className="card-content">
						<span>Base Yield</span>
						<span>{cardContent.baseYield}</span>
					</div>
					<div className="card-content">
						<span>Price Drop Protection</span>
						<span>{cardContent.priceDropProtection}</span>
					</div>
					<div className="card-content">
						<span>Tenor</span>
						<span>{cardContent.tenor}</span>
					</div>
				</div>
				{cardContent.coins.map((item, index) => {
					return <Coin key={index} coinContent={item} />;
				})}
			</div>
		);
	}
}
