// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
	align-items: center;
	display: flex;
}
header img {
	height: 97px;
	width: 105px;
}
.home-link {
	align-items: center;
	color: #ffffff;
	display: flex;
	flex: 1 1;
	font-size: 40px;
	font-weight: 500;
	line-height: 52px;
	text-decoration: none;
}

nav {
	display: flex;
	flex: 1 1;
}
nav a {
	color: #ffffff;
	font-size: 23px;
	font-weight: 800;
	line-height: 31px;
	margin-right: 100px;
	text-decoration: none;
}

header .avatar {
	align-items: center;
	display: flex;
	margin-right: 50px;
}
header .metamask {
	height: 32px;
	margin-right: 20px;
	width: 33px;
}

header .avatar .link {
	color: #ffffff;
	font-size: 19px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/header.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,aAAa;AACd;AACA;CACC,YAAY;CACZ,YAAY;AACb;AACA;CACC,mBAAmB;CACnB,cAAc;CACd,aAAa;CACb,SAAO;CACP,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,qBAAqB;AACtB;;AAEA;CACC,aAAa;CACb,SAAO;AACR;AACA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,mBAAmB;CACnB,qBAAqB;AACtB;;AAEA;CACC,mBAAmB;CACnB,aAAa;CACb,kBAAkB;AACnB;AACA;CACC,YAAY;CACZ,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,qBAAqB;AACtB","sourcesContent":["header {\n\talign-items: center;\n\tdisplay: flex;\n}\nheader img {\n\theight: 97px;\n\twidth: 105px;\n}\n.home-link {\n\talign-items: center;\n\tcolor: #ffffff;\n\tdisplay: flex;\n\tflex: 1;\n\tfont-size: 40px;\n\tfont-weight: 500;\n\tline-height: 52px;\n\ttext-decoration: none;\n}\n\nnav {\n\tdisplay: flex;\n\tflex: 1;\n}\nnav a {\n\tcolor: #ffffff;\n\tfont-size: 23px;\n\tfont-weight: 800;\n\tline-height: 31px;\n\tmargin-right: 100px;\n\ttext-decoration: none;\n}\n\nheader .avatar {\n\talign-items: center;\n\tdisplay: flex;\n\tmargin-right: 50px;\n}\nheader .metamask {\n\theight: 32px;\n\tmargin-right: 20px;\n\twidth: 33px;\n}\n\nheader .avatar .link {\n\tcolor: #ffffff;\n\tfont-size: 19px;\n\tfont-weight: 700;\n\tline-height: 24px;\n\ttext-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
