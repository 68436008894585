// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Inter.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: "Inter";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
html {
	background-color: #141428;
	color: #ffffff;
	font-family: "Inter";
}
body {
	margin: 0;
}

.devider {
	height: 4px;
	background-color: #ffffff;
	border-radius: 2px;
}
.mt-20 {
	margin-bottom: 20px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-80 {
	margin-top: 80px;
}

.mb-20 {
	margin-bottom: 20px;
}
.mb-30 {
	margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,4CAA6B;AAC9B;AACA;CACC,yBAAyB;CACzB,cAAc;CACd,oBAAoB;AACrB;AACA;CACC,SAAS;AACV;;AAEA;CACC,WAAW;CACX,yBAAyB;CACzB,kBAAkB;AACnB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;AACA;CACC,mBAAmB;AACpB","sourcesContent":["@font-face {\n\tfont-family: \"Inter\";\n\tsrc: url(\"./fonts/Inter.ttf\");\n}\nhtml {\n\tbackground-color: #141428;\n\tcolor: #ffffff;\n\tfont-family: \"Inter\";\n}\nbody {\n\tmargin: 0;\n}\n\n.devider {\n\theight: 4px;\n\tbackground-color: #ffffff;\n\tborder-radius: 2px;\n}\n.mt-20 {\n\tmargin-bottom: 20px;\n}\n.mt-30 {\n\tmargin-top: 30px;\n}\n.mt-50 {\n\tmargin-top: 50px;\n}\n.mt-80 {\n\tmargin-top: 80px;\n}\n\n.mb-20 {\n\tmargin-bottom: 20px;\n}\n.mb-30 {\n\tmargin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
