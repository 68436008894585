import { PureComponent } from "react";
// images
import invexo from "../images/invexo.png";
import discord from "../images/discord.png";
import medium from "../images/medium.png";
import linkedin from "../images/linkedin.png";
// styles
import "./styles/share-link.css";

export default class ShareLink extends PureComponent {
	render() {
		return (
			<div className="share-link">
				<img src={invexo} alt="" />
				<a href="https://discord.gg/J4AjC6vp" target="_blank" rel="noreferrer">	<img src={discord} alt="" /></a>
				<a href="https://medium.com/@fintank.web3" target="_blank" rel="noreferrer"><img src={medium} alt="" /></a>
				<a href="https://www.linkedin.com/company/tank-network/about/" target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a>
			</div>
		);
	}
}
