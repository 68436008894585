import { useState, useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { useWallet } from "../services/walletContextProvider";
import Header from "../components/Header";
import { formatBalance } from "../services/utils";
import { VAULT_ADDRESS } from "../config/address"
// styles
import "./styles/electric.css";
import useDeposit from "../hooks/useDeposit";

export default function Electric() {
	window.scrollTo(0, 0);

	const [isConnected, setIsConnected] = useState(false);

	const { depositToContract, isPending } = useDeposit();


	const [amount, setAmount] = useState("0.00");
	const handleAmountChange = (event) => {
		const inputValue = event.target.value;
		if (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === "") {
			setAmount(inputValue);
		}
	};

	const handleInputFocus = () => {
		if (amount === "0.00") {
			setAmount("");
		}
	};

	// Connect metamask
	const [hasProvider, setHasProvider] = useState(null);
	const initialState = {
		accounts: [],
		balance: "",
		chainId: "",
	};
	const { setWallet } = useWallet();

	useEffect(() => {
		const refreshAccounts = (accounts) => {
			if (accounts.length > 0) {
				updateWallet(accounts);
			} else {
				// if length 0, user is disconnected
				setWallet(initialState);
			}
		};

		const refreshChain = (chainId) => {
			setWallet((wallet) => ({ ...wallet, chainId }));
		};

		const getProvider = async () => {
			const provider = await detectEthereumProvider({ silent: true });
			setHasProvider(Boolean(provider));

			if (provider) {
				const accounts = await window.ethereum.request({
					method: "eth_accounts",
				});
				refreshAccounts(accounts);
				window.ethereum.on("accountsChanged", refreshAccounts);
				window.ethereum.on("chainChanged", refreshChain);

				if (accounts.length > 0) {
					setIsConnected(true);
				}
			}
		};

		getProvider();

		return () => {
			window.ethereum?.removeListener("accountsChanged", refreshAccounts);
			window.ethereum?.removeListener("chainChanged", refreshChain);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateWallet = async (accounts) => {
		const balance = formatBalance(
			await window.ethereum.request({
				method: "eth_getBalance",
				params: [accounts[0], "latest"],
			})
		);
		const chainId = await window.ethereum.request({
			method: "eth_chainId",
		});
		setWallet({ accounts, balance, chainId });
	};

	const handleConnect = async () => {
		try {
			await switchToGoerli();

			let accounts = await window.ethereum.request({
				method: "eth_requestAccounts",
			});
			updateWallet(accounts);

			setIsConnected(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeposit = async () => {
		if (amount && parseFloat(amount) > 0) {
			try {
				await depositToContract(VAULT_ADDRESS, amount);
			} catch (error) {
				console.error("Error during deposit:", error);
			}
		} else {
			alert("Please enter a valid deposit amount.");
		}
	};

	const rpc_url = `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`

	const switchToGoerli = async () => {
		try {
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: '0xaa36a7' }],
			});
		} catch (switchError) {
			if (switchError.code === 4902) {
				try {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [{
							chainId: '0xaa36a7',
							chainName: 'Sepolia Testnet',
							nativeCurrency: {
								name: 'Sepolia ETH',
								symbol: 'seth',
								decimals: 18
							},
							rpcUrls: [rpc_url],
							blockExplorerUrls: ['https://goerli.etherscan.io/']
						}],
					});
				} catch (addError) {
					console.error(addError);
				}
			} else {
				console.error(switchError);
			}
		}
	};


	return (
		<div className="electric">
			<Header isRenderAvatar="true" />
			<div className="devider"></div>
			<section className="electric-header">
				<h1>Single Stock Vault</h1>
				<p className="title">Electric</p>
				<div className="list">
					<div className="apy">
						<div>20.00%</div>
						<div>FIXED</div>
					</div>
					<div className="asset">
						<div>USDC</div>
						<div>STAKING</div>
						<div>ASSET</div>
					</div>
					<div className="asset">
						<div>LINKED</div>
						<div>ASSET</div>
					</div>
					<div className="asset">
						<div>21.00%</div>
						<div>DOWNSIDE</div>
						<div>PROTECTION</div>
					</div>
					<div className="asset">
						<div>$ 486,645.64</div>
						<div>PRODUCT </div>
						<div>TVL</div>
					</div>
				</div>
			</section>
			<section className="electric-content">
				<div className="navigate">
					<p>Product Summary</p>
					<p>Payoff Scenarios</p>
					<p>Product Description</p>
					<p>Backtesting Analytics</p>
					<p>Risks and Mitigation</p>
				</div>
				<div className="main-content">
					<div className="product-summary">
						<h2>Product Summary</h2>
						<p className="description">
							This product benefits from the equity appreciation
							of the leading growth tech stock TSLA
						</p>
						<p>Mono underlying structued note linked to TSLA.O</p>
						<p>Earn up to 20.00% APY on USDC deposit</p>
						<p>
							Your principal is protected from 21.00% price drop
						</p>
						<p>
							Your capital is locked or up to 3 months with chance
							of early termination
						</p>
						<p>Coupons are paid monthly</p>
					</div>
					<h2 className="mt-80">Payoff Scenarios</h2>
					<div className="white-description">
						<p>Mandatory Early Termination</p>
					</div>
					<p>
						The product has an auotcall condition that would be
						observed monthly through out the whole trade life.{" "}
					</p>
					<p>
						if on any autocall valuation date the underlying stock
						closes above the autocall level, the product will be
						terminated automatically. Investors will receive the
						monthly coupon of 1.6667% and get back their deposited
						capital.
					</p>
					<p>
						There will be no futher payment after the termination
						event.
					</p>
					<div className="white-description">
						<p>Normal expiry, closing spot above strike level</p>
					</div>
					<p>
						Investors will receive the last last month coupon and
						their deposited capital.
					</p>
					<div className="white-description">
						<p>Normal expiry, closing spot below strike level</p>
					</div>
					<p>
						Investors will receive the last last month coupon and a
						partial amount of their deposited capital. The final
						withdrawable amount will be determeined by the stock
						price at maturity St. The final amount would be
						deposited capital x St/K.
					</p>
					<p>St: stock price at maturity</p>
					<p>K: 79% x initial stock price</p>

					<h2 className="product-description">Product Description</h2>
					<p>
						This vault trades an RWA exotic structured product that
						linked to real world equity TSLA.O. Investors accrue
						high yield payments on their deposited capital which is
						paid monthly.{" "}
					</p>
					<p>
						The product has an early termination (autocall) feature
						- if on any autocall valuation date the underlying stock
						closes above the autocall level, the product will be
						terminated automatically. Investors will receive their
						accrued coupon and get their deposited capital in
						advance, giving them the chance to compound their
						interest and re-allocate their capital freely.
					</p>
					<p>
						At expiry, the initial deposit is returned to investors
						in full if the price of an underlying asset (e.g.
						TSLA.O) at maturity closes above the strike level. Pure
						Options vaults are valuable investments for investors
						who seek superior yields and have positively or
						moderately bullish views on the price of the underlying
						stock.
					</p>
					<div className="user-pnl mt-50">
						<h2>Backtesting Analysis</h2>
						<h3>User PNL over time</h3>
					</div>
				</div>
				<div className="deposit">
					<p>DEPOSIT</p>
					<div className="amount">
						<label htmlFor="amount">AMOUNT</label>
						<input
							type="text"
							name="amount"
							id="amount"
							value={amount}
							onChange={handleAmountChange}
							onFocus={handleInputFocus}
						/>
						<span>USDC</span>
					</div>
					<button
						className="mt-30"
						disabled={!hasProvider || !window.ethereum?.isMetaMask || isPending}
						onClick={isConnected ? handleDeposit : handleConnect}
					>
						{isPending ? "LOADING..." : isConnected ? "DEPOSIT" : "CONNECT WALLET"}
					</button>
				</div>
			</section>
		</div>
	);
}
