import Header from "../components/Header";
//images
import greendotUrl from "../images/greendot.png";
import chevronUpUrl from "../images/chevron-up.png";
import { VAULT_ADDRESS } from "../config/address"
//styles
import "./styles/portfolio.css";
import useWithdraw from "../hooks/useWithdraw";

export default function Portfolio() {
	const { claimInterest, claimPrincipal, isInterestPending, isPrincipalPending } = useWithdraw()

	const handleWithdrawInterest = async () => {
		await claimInterest(VAULT_ADDRESS);
	}

	const handleWithdrawPrincipal = async () => {
		await claimPrincipal(VAULT_ADDRESS);
	}

	return (
		<div>
			<Header isRenderAvatar="true" />

			<div className="devider"></div>
			<div className="porfolio">
				<section className="porfolio-title">
					<h1>My Portfolio</h1>
					<h2>Investment Summary</h2>
					<p className="balance-title">CURRENT BALANCE Ⓒ</p>
					<p className="balance">$ 100,356.00</p>
				</section>
				<section className="profolio-electric">
					<h2>Investment By Product( 1 )</h2>
					<div className="electric-content">
						<div className="title">Electric</div>
						<div className="balance">
							<p>PRODUCT BALANCE</p>
							<p>$ 100,356.00</p>
						</div>
						<div className="balance">
							<p>DEPOSITS</p>
							<p>$ 100,000.00</p>
						</div>
						<div className="balance">
							<p>YIELD EARNED</p>
							<p>$356.00</p>
						</div>
					</div>
				</section>
				<section className="detail-card">
					<div className="title">
						<div className="apy">
							<span>$ 10.29</span>
							<span>20% APY</span>
						</div>
						<div className="trade-yield">
							<img src={greendotUrl} alt="" />
							<span>Trading and Yielding</span>
							<img src={chevronUpUrl} alt="" />
						</div>
					</div>
					<div className="balance-trade">
						<div className="balance">
							<p>
								<span>BALANCE GENERATING YIELD</span>
								<span>$ 10.00</span>
							</p>
							<p>
								<span>NET YIELD ACCRUING THIS TRADE</span>
								<span>$ 0.29</span>
							</p>
							<p>
								<span>QUEUED WITHDRAWALS</span>
								<span className="tokens">O tokens</span>
							</p>
							<p className="deposits">DEPOSITS ROLLING OVER :</p>
						</div>
						<div className="trade">
							<p>
								<span>TRADE START DATE</span>
								<span>7/13/2023</span>
							</p>
							<p>
								<span className="text-normal">
									TRADE END/ ROLLOVER DATE
								</span>
								<span>8/9/2023</span>
							</p>
							<p>
								<span>VAULT ADDRESS</span>
								<span className="vault">Oxe95B ... eB58</span>
							</p>
							<p>
								<span>ARB/ USD KNOCK- IN BARRIER PRICE</span>
								<span className="text-normal">$ 0.56</span>
							</p>
							<p>
								<span className="text-normal">
									OP/ USD KNOCK- IN BARRIER PRICE
								</span>
								<span className="text-normal">$ 0.64</span>
							</p>
						</div>
					</div>
					<div>
						<div className="token">
							<span>Coupon Token 1</span>
							<span>Eligible quantity to withdraw: 10</span>
							<div>
								<button onClick={handleWithdrawInterest} disabled={isInterestPending}>
									{isInterestPending ? "Loading..." : "Withdraw"}
								</button>
							</div>
						</div>
						<div className="token">
							<span>Coupon Token 2</span>
							<span>Eligible quantity to withdraw: 0</span>
							<div>
								<button className="disabled-button">
									Deposit Now
								</button>
							</div>
						</div>
						<div className="token">
							<span>Redemption Token</span>
							<span>Eligible quantity to withdraw: 0</span>
							<div>
								<button onClick={handleWithdrawPrincipal} disabled={isPrincipalPending}>
									{isPrincipalPending ? "Loading..." : "Withdraw"}
								</button>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}
