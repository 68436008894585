import { useWallet } from "../services/walletContextProvider";
import { formatAccountId } from "../services/utils";
import { Link } from "react-router-dom";
// images
import logo from "../images/logo.png";
import metamask from "../images/metamask.png";
// styles
import "./styles/header.css";

export default function Header(props) {
	const { isRenderAvatar } = props;
	const { wallet } = useWallet();
	let displayAcc = "";
	if (wallet?.accounts.length) {
		displayAcc = formatAccountId(wallet?.accounts[0]);
	}

	function renderAvatar() {
		return (
			<div className="avatar">
				<img className="metamask" src={metamask} alt="" />
				<Link className="link" to="/portfolio">
					{displayAcc}
				</Link>
			</div>
		);
	}
	return (
		<header className="header">
			<Link className="home-link" to="/">
				<img src={logo} alt="logo" />
				<span>FinTank</span>
			</Link>
			<nav>
				<Link to="/products">Vaults</Link>
				<a
					href="https://drive.google.com/file/d/1z8BqQuiR1v0Wommx9oZU5lwKnrswMCCd/view?usp=drive_link"
					target="_blank"
					rel="noreferrer"
				>
					Docs
				</a>
			</nav>
			{isRenderAvatar ? renderAvatar() : ""}
		</header>
	);
}
