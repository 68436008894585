// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-link img {
	height: 21px;
	padding-right: 5px;
	width: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/share-link.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,kBAAkB;CAClB,WAAW;AACZ","sourcesContent":[".share-link img {\n\theight: 21px;\n\tpadding-right: 5px;\n\twidth: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
