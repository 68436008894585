import { PureComponent } from "react";
import { Link } from "react-router-dom";
// styles
import "./styles/coin.css";

export default class Coin extends PureComponent {
	render() {
		const { coinContent } = this.props;

		return (
			<div className="coin">
				<h1>{coinContent?.title}</h1>
				<img src={coinContent?.imgSrc} alt="" />
				<Link
					className={`link ${
						coinContent.disableButton ? "disable-link" : ""
					}`}
					to={coinContent.disableButton ? "#" : "/electric"}
					disabled={coinContent.disableButton}
				>
					Deposit Now
				</Link>
			</div>
		);
	}
}
