import { Routes, Route } from "react-router-dom";
import { WalletProvider } from "./services/walletContextProvider";
import Electric from "./pages/Electric";
import Portfolio from "./pages/Portfolio";
import Products from "./pages/Products";
import Home from "./pages/Home";

function App() {
	return (
		<WalletProvider>
			<div className="App">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/products" element={<Products />} />
					<Route path="/electric" element={<Electric />} />
					<Route path="/portfolio" element={<Portfolio />} />
				</Routes>
			</div>
		</WalletProvider>
	);
}
export default App;
