import React, { createContext, useContext, useState } from "react";

const WalletContext = createContext();

export function WalletProvider({ children }) {
	const initialState = {
		accounts: [],
		balance: "",
		chainId: "",
	};
	const [wallet, setWallet] = useState(initialState);

	return (
		<WalletContext.Provider value={{ wallet, setWallet }}>
			{children}
		</WalletContext.Provider>
	);
}

export function useWallet() {
	return useContext(WalletContext);
}
