import { ethers } from 'ethers';
import { useState } from 'react';
import { CONTRACT_ADDRESS, TOKEN_ADDRESS } from '../config/address';
import CONTRACT_ABI from '../config/contract.json';
import ERC20_ABI from '../config/erc20ABI.json';

const useDeposit = () => {
    const [isPending, setIsPending] = useState(false);
    const decimalPlaces = 6;

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const depositToContract = async (vaultAddress, amount) => {
        try {
            const accounts = await provider.listAccounts();
            if (accounts.length === 0) {
                throw new Error("Please connect and unlock your MetaMask wallet!");
            }

            const signer = provider.getSigner();
            const userAddress = accounts[0];

            const network = await provider.getNetwork();
            if (network.chainId !== 11155111) {
                throw new Error("Please connect to the Goerli test network!");
            }

            setIsPending(true);

            const tokenContract = new ethers.Contract(TOKEN_ADDRESS, ERC20_ABI, signer);
            const userBalance = await tokenContract.balanceOf(userAddress);

            if (userBalance.lt(ethers.utils.parseUnits(amount, decimalPlaces))) {
                throw new Error("Insufficient token balance");
            }

            const allowance = await tokenContract.allowance(userAddress, CONTRACT_ADDRESS);
            if (allowance.lt(ethers.utils.parseUnits(amount, decimalPlaces))) {
                if (!allowance.isZero()) {
                    await tokenContract.approve(CONTRACT_ADDRESS, 0);
                }
                const approveTx = await tokenContract.approve(CONTRACT_ADDRESS, ethers.utils.parseUnits(amount, decimalPlaces));
                await approveTx.wait();
                alert("Allowance set. You can proceed with the deposit.");
            }

            const depositContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

            const estimateGas = await depositContract.estimateGas.deposit(vaultAddress, ethers.utils.parseUnits(amount, decimalPlaces));
            const gasLimit = estimateGas.add(ethers.BigNumber.from("10000"));

            const tx = await depositContract.deposit(vaultAddress, ethers.utils.parseUnits(amount, decimalPlaces), {
                gasLimit: gasLimit
            });
            await tx.wait();

            alert("Deposit Success");
        } catch (err) {
            console.error("Deposit Error:", err.message);
            alert(err.message);
        } finally {
            setIsPending(false);
        }
    };


    return {
        isPending,
        depositToContract
    };
};

export default useDeposit;
