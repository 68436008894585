// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coin {
	align-items: center;
	background-color: #ffffff;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	height: 376px;
	margin-right: 20px;
	text-align: center;
	width: 317px;
}
.coin h1 {
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	margin-top: 30px;
}
.coin img {
	border-radius: 8px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100px;
	margin-top: 60px;
	margin-bottom: 30px;
	width: 100px;
}
.coin .link {
	border: 0;
	box-sizing: border-box;
	border-radius: 11px;
	background-color: #fcd12e;
	color: #181920;
	font-size: 12px;
	font-family: "Inter";
	font-weight: 600;
	line-height: 19px;
	outline: none;
	padding: 8px 22px;
	text-decoration: none;
}

.coin .disable-link {
	background-color: #7d848f;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/coin.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,yBAAyB;CACzB,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,aAAa;CACb,kBAAkB;CAClB,kBAAkB;CAClB,YAAY;AACb;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,gBAAgB;AACjB;AACA;CACC,kBAAkB;CAClB,kCAAkC;CAClC,sBAAsB;CACtB,4BAA4B;CAC5B,aAAa;CACb,gBAAgB;CAChB,mBAAmB;CACnB,YAAY;AACb;AACA;CACC,SAAS;CACT,sBAAsB;CACtB,mBAAmB;CACnB,yBAAyB;CACzB,cAAc;CACd,eAAe;CACf,oBAAoB;CACpB,gBAAgB;CAChB,iBAAiB;CACjB,aAAa;CACb,iBAAiB;CACjB,qBAAqB;AACtB;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".coin {\n\talign-items: center;\n\tbackground-color: #ffffff;\n\tborder-radius: 10px;\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 376px;\n\tmargin-right: 20px;\n\ttext-align: center;\n\twidth: 317px;\n}\n.coin h1 {\n\tfont-size: 20px;\n\tfont-weight: 700;\n\tline-height: 28px;\n\tmargin-top: 30px;\n}\n.coin img {\n\tborder-radius: 8px;\n\tbackground-position: center center;\n\tbackground-size: cover;\n\tbackground-repeat: no-repeat;\n\theight: 100px;\n\tmargin-top: 60px;\n\tmargin-bottom: 30px;\n\twidth: 100px;\n}\n.coin .link {\n\tborder: 0;\n\tbox-sizing: border-box;\n\tborder-radius: 11px;\n\tbackground-color: #fcd12e;\n\tcolor: #181920;\n\tfont-size: 12px;\n\tfont-family: \"Inter\";\n\tfont-weight: 600;\n\tline-height: 19px;\n\toutline: none;\n\tpadding: 8px 22px;\n\ttext-decoration: none;\n}\n\n.coin .disable-link {\n\tbackground-color: #7d848f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
