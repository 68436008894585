import VaultCard from "../components/VaultCard";
import Header from "../components/Header";
//images
import usdc from "../images/usdc.png";
import tusd from "../images/tusd.png";
import btc from "../images/btc.png";
//styles
import "./styles/products.css";

const vaultCards = [
	{
		title: "Single Stock Vault",
		header: "Electric",
		description:
			"High yield fixed coupon note  linked to leading EV maker TSLA",
		underlyingAssets: "TESLA.O",
		baseYield: "20.00%",
		priceDropProtection: "21.00%",
		tenor: "3 Month",
		coins: [
			{
				title: "USDC Pool",
				imgSrc: usdc,
				disableButton: false,
			},
			{
				title: "TUSD Pool",
				imgSrc: tusd,
				disableButton: true,
			},
			{
				title: "BTC Pool",
				imgSrc: btc,
				disableButton: true,
			},
		],
	},
	{
		title: "Basket Vault",
		header: "Tech Flux",
		description:
			"Aggressive participation note linked to a growth tech basket",
		underlyingAssets: "AMD.O, TSLA.O",
		baseYield: "41.91%",
		priceDropProtection: "1.00%",
		tenor: "3 Month",
		coins: [
			{
				title: "USDC Pool",
				imgSrc: usdc,
				disableButton: true,
			},
			{
				title: "TUSD Pool",
				imgSrc: tusd,
				disableButton: true,
			},
			{
				title: "BTC Pool",
				imgSrc: btc,
				disableButton: true,
			},
		],
	},
	{
		title: "Single Stock Vault",
		header: "Estee Lauder",
		description: "Conservative strategy note with decent price protection",
		underlyingAssets: "EL.N",
		baseYield: "15.00%",
		priceDropProtection: "13.00%",
		tenor: "3 Month",
		coins: [
			{
				title: "USDC Pool",
				imgSrc: usdc,
				disableButton: true,
			},
			{
				title: "TUSD Pool",
				imgSrc: tusd,
				disableButton: true,
			},
			{
				title: "BTC Pool",
				imgSrc: btc,
				disableButton: true,
			},
		],
	},
];

function renderVaultCard() {
	return vaultCards.map((item, index) => {
		return <VaultCard key={index} cardContent={item} />;
	});
}

export default function Products() {
	return (
		<div className="products">
			<Header isRenderAvatar="true" />
			<div className="devider"></div>
			<section className="products-content">
				<div className="text-content">
					<h1>Products</h1>
					<p>
						FinTank is the first RWA (Real World Asset) structured
						Derivative DeFi Protocol.
					</p>
					<p>
						We tokenize equity structured notes and offer high yield
						investment oppotunities linked to real world financial
						assets to diversive your crypto portfolio
					</p>
					<div className="text">TOTAL VALUE LOCKED</div>
					<button>Coming Soon</button>
				</div>
			</section>
			<section className="vaults-content">{renderVaultCard()}</section>
			<section className="products-footer"></section>
		</div>
	);
}
