// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vault-card {
	background-color: #d0dfdb;
	color: #1d2329;
	display: flex;
	margin-bottom: 40px;
	padding-top: 25px;
	padding-bottom: 15px;
	width: 1351px;
}
.card-description {
	padding-left: 15px;
	padding-right: 25px;
}
.vault-card .title {
	background-color: #112438;
	border-radius: 7px;
	color: #ffffff;
	margin: auto;
	padding: 5px;
	text-align: center;
	width: 170px;
}
.vault-card h2 {
	font-size: 29px;
	font-weight: 800;
	line-height: 37px;
	margin-top: 60px;
	text-align: center;
}
.vault-card p {
	font-size: 11px;
	font-weight: 500;
	line-height: 14px;
	margin-bottom: 40px;
}
.vault-card .card-content {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}
.vault-card .card-content span:first-child {
	color: #474c57;
	font-size: 13px;
	font-weight: 500;
	line-height: 19px;
}

.vault-card .card-content span:last-child {
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/vault-card.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,cAAc;CACd,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,oBAAoB;CACpB,aAAa;AACd;AACA;CACC,kBAAkB;CAClB,mBAAmB;AACpB;AACA;CACC,yBAAyB;CACzB,kBAAkB;CAClB,cAAc;CACd,YAAY;CACZ,YAAY;CACZ,kBAAkB;CAClB,YAAY;AACb;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,gBAAgB;CAChB,kBAAkB;AACnB;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,mBAAmB;AACpB;AACA;CACC,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;AACpB;AACA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,iBAAiB;AAClB","sourcesContent":[".vault-card {\n\tbackground-color: #d0dfdb;\n\tcolor: #1d2329;\n\tdisplay: flex;\n\tmargin-bottom: 40px;\n\tpadding-top: 25px;\n\tpadding-bottom: 15px;\n\twidth: 1351px;\n}\n.card-description {\n\tpadding-left: 15px;\n\tpadding-right: 25px;\n}\n.vault-card .title {\n\tbackground-color: #112438;\n\tborder-radius: 7px;\n\tcolor: #ffffff;\n\tmargin: auto;\n\tpadding: 5px;\n\ttext-align: center;\n\twidth: 170px;\n}\n.vault-card h2 {\n\tfont-size: 29px;\n\tfont-weight: 800;\n\tline-height: 37px;\n\tmargin-top: 60px;\n\ttext-align: center;\n}\n.vault-card p {\n\tfont-size: 11px;\n\tfont-weight: 500;\n\tline-height: 14px;\n\tmargin-bottom: 40px;\n}\n.vault-card .card-content {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-bottom: 15px;\n}\n.vault-card .card-content span:first-child {\n\tcolor: #474c57;\n\tfont-size: 13px;\n\tfont-weight: 500;\n\tline-height: 19px;\n}\n\n.vault-card .card-content span:last-child {\n\tfont-size: 14px;\n\tfont-weight: 700;\n\tline-height: 21px;\n\ttext-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
